<template>
    <BT-Blades :anchorBlades="[{ bladeName: 'journey-templates' }]">
        <template v-slot="bladesData">
            <Journey-Templates-Blade :bladesData="bladesData" />
            <Journey-Template-Blade :bladesData="bladesData" />
            <Journey-Pointer-Blade :bladesData="bladesData" />
        </template>
    </BT-Blades>
</template>

<script>
export default {
    name: 'Journey-Templates-Hub',
    components: {
        JourneyPointerBlade: () => import('~home/journey-pointers/Journey-Pointer-Blade.vue'),
        JourneyTemplatesBlade: () => import('~home/journey-templates/Journey-Templates-Blade.vue'),
        JourneyTemplateBlade: () => import('~home/journey-templates/Journey-Template-Blade.vue'),
    }
}
</script>